<template>

  <div>

    <div
      id="hero-panel"
      :style="{ 'background-image': 'url(' + model.panelOverviewImageUrl + ')' }"
    >
      <a
       @click.prevent="playVideo(overviewVideoUrl)"
       class="p-absolute hide-sm hide-md hide-lg"
       href="#"
      >
        <img
          class="play-icon"
          src="@/assets/play.svg"
        >
      </a>

      <div class="name-event-container">
        <div style="margin: 25px; width: auto; height: auto; background-color: rgba(0,0,0,.7)">
          <h1
            :class="activeTheme.fontBold"
            style="font-size: 28px; text-transform: uppercase; padding: 20px 20px 0px 20px; margin: 0px; color: white;"
          >
            {{ getHeroYearMake() }}
          </h1>
          <h1
            :class="activeTheme.fontBold"
            style="font-size: 45px; color: white; text-transform: uppercase; margin-top: -5px; margin-top: -10px; margin-bottom: 0px; padding: 0px 20px 20px 20px;"
          >
            {{ model.model }}
          </h1>
          <div style="width: 100%; text-align: center;">
            <img
              v-if="model.eventLogoUrl"
              :src="model.eventLogoUrl"
              style="padding: 0px 20px 20px 20px; max-height: 160px;"
            >
          </div>
        </div>
      </div>

      <!-- <hero-incentive-box class="hero-incentive-box-position"></hero-incentive-box> -->

      <primary-btn
        :text="getInventoryModelText(model.model)"
        class="primary-btn-position hide-sm hide-md hide-lg"
        showChevron
      >
      </primary-btn>

    </div>

  </div>

</template>

<script>

// import HeroIncentiveBox from '@/components/HeroIncentiveBox.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';

export default {

  name: "the-hero-panel",

  components: {
    // HeroIncentiveBox,
    PrimaryBtn,
  },

  props: {
    overviewVideoUrl: {
      type: String,
    },
    model: {
      type: Object,
    }
  },

  methods: {

    playVideo(url) {
      this.$store.commit('toggleModal');
      this.$store.commit("setVideoActiveUrl", url);
      this.$store.commit('toggleVideo');
    },

    getInventoryModelText(model) {
      if(this.model.make == 'jeep') {
        return "explore " + model + ' inventory';
      } else {
        return "view " + model + ' inventory';
      }
    },

    getHeroYearMake() {
      if(this.model.model.toLowerCase() == 'wagoneer' || this.model.model.toLowerCase() == 'grand wagoneer') {
        return this.model.year;
      } else {
        return this.model.year + ' ' + this.model.make;
      }
    },

  },

  computed: {

    activeTheme() {
      return this.$store.getters.activeTheme;
    }

  }

};

</script>

<style scoped>

  .play-icon {
    z-index: 50;
    width: 100px;
  }

  .play-icon:hover {
    opacity: 0.8;
  }

  #hero-panel {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    /* background-image: url('https://stellantis.akamaized.net/images/program/lj28u/ark/jeep/2022/compass/panel_overview.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .name-event-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  @media only screen and (max-width: 768px) {

    #hero-panel {
      width: 100%;
      height: 500px;
      background-position: 70%;
    }

    .hero-incentive-box-position {
      width: 90%;
    }

  .name-event-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  }

  @media only screen and (max-width: 992px) and (min-width: 769px) {

    .hero-incentive-box-position {
      width: 80%;
    }

  }
  
  @media only screen and (max-width: 1400px) and (min-width: 993px){

    .hero-incentive-box-position {
      left: 30%;
    }

    .primary-btn-position {
      position: absolute;
      margin-top: 500px;
    }

  }

  @media only screen and (min-width: 1400px) {
    
    .hero-incentive-box-position {
      left: 30%;
    }

    .primary-btn-position {
      position: absolute;
      margin-top: 450px;
    }

  }



</style>
