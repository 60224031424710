<template>
  <!-- default container -->
  <div>
    <the-dealer-bar :activeMake="model.make" :dealer="dealer"></the-dealer-bar>
    <the-hero-panel :model="model" :overviewVideoUrl="overviewVideoUrl"></the-hero-panel>
    <the-video-thumbnails :videos="videosSorted"></the-video-thumbnails>
    <!-- <the-summary-panel></the-summary-panel> -->
    <!-- <the-feature-cards :model="model"></the-feature-cards> -->
    
    <the-feature-panels :model="model" :videos="videosSorted" :dealer="dealer"></the-feature-panels>
    <!-- <the-inventory :data="inventory"></the-inventory> -->
    <the-recommended-models
      :modelStubData="modelStubData"
      :activeMake="stubModel.make"
      style="margin-top: 60px;"
    ></the-recommended-models>
    <the-footer></the-footer>

    <!-- sticky button for mobile only -->
    <primary-btn
      :text="getInventoryModelText(stubModel.model)"
      :url="dealer.inventoryUrl"
      :fullWidth="true"
      style="position: fixed; bottom: 0px; width: 100%; z-index: 100;"
      class="hide-xl hide-xxl"
      showChevron
    >
    </primary-btn>

  </div>
  <!-- end default container -->
</template>

<script>

import modelStubData from "@/assets/models.json";
import TheDealerBar from '@/components/TheDealerBar.vue';
import TheHeroPanel from '@/components/TheHeroPanel.vue';
import TheVideoThumbnails from '@/components/TheVideoThumbnails.vue';
// import TheSummaryPanel from '@/components/TheSummaryPanel.vue';
// import TheFeatureCards from '@/components/TheFeatureCards.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';
import TheFeaturePanels from '@/components/TheFeaturePanels.vue';
// import TheInventory from '@/components/TheInventory.vue';
import TheRecommendedModels from '@/components/TheRecommendedModels.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'Videos',
  components: {
    TheDealerBar,
    TheHeroPanel,
    TheVideoThumbnails,
    // TheSummaryPanel,
    // TheFeatureCards,
    PrimaryBtn,
    TheFeaturePanels,
    // TheInventory,
    TheRecommendedModels,
    TheFooter,
  },

  watch: {
    '$route.query.d': function () {
      this.getDealer();
    },
    '$route.query.m': function () {
      this.setStubModel();
      this.getModel();
      this.setPageTitle(this.model.yearMakeModel);
    },
  },

  created() {
    this.setStubModel();
    this.getDealer();
    this.getModel();
    this.setPageTitle();
  },

  data() {
    return {
      dealerCode: this.$route.query.d,
      modelCode: this.$route.query.m,
      dealer: {},
      model: {},
      modelStubData: modelStubData,
    };
  },

  methods: {

    setStubModel() {
      this.stubModel = this.modelStubData.find(model => model.modelCode == this.$route.query.m);
      if(!this.stubModel) {
        window.location.href = '/model-select?d=' + this.$route.query.d;
      }
      this.stubModel.make = this.stubModel.make.toLowerCase();
    },

    getDealer() {
      var self = this;
      this.axios
        .post('https://data.dealervideos.com/v2.0/modelShowcase/GetModelShowcaseNavigationList', {
          programCode: process.env.VUE_APP_PROGRAM_CODE,
          dealerCode: self.dealerCode,
          campaignState: process.env.VUE_APP_CAMPAIGN_STATE,
        })
        .then(response => {
          self.dealer = response.data.modelShowcaseProgramPackage;
        })
        .catch(function() {
          window.location.href = "/not-found";
      });
    },

    getModel() {
      var self = this;
      this.axios
        .post('https://data.dealervideos.com/v2.0/modelShowcase/GetModelShowcaseModelYear', {
          programCode: process.env.VUE_APP_PROGRAM_CODE,
          dealerCode: self.dealerCode,
          // modelCode: self.modelCode,
          modelCode: '8KCIEU5',
          campaignState: process.env.VUE_APP_CAMPAIGN_STATE,
        })
        .then(response => {
          let data = {};
          data = Object.assign(response.data.modelShowcaseModelYear, this.stubModel);
          self.model = data;
          // now that we have our model data situated, let's deliver it to our state to set our theme
          // probably should put this somewhere else in our application, but it does work here, so.....
          this.$store.dispatch('setActiveTheme', data);
        })
        .catch(function() {
          window.location.href = '/model-select?d=' + this.$route.query.d;
      });
    },

    setPageTitle() {
      document.title = 'Learn About the ' + this.stubModel.model;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    getInventoryModelText(model) {
      return 'view ' + model + ' inventory';
    },

  },

  computed: {

    videoActive() {
      return this.$store.state.videoActive;
    },

    videoActiveUrl() {
      return this.$store.state.videoActiveUrl;
    },

    videosSorted() {
      let overview, performance, safety, technology;
      this.model.modelYearVideoList.map((video)=>{
        switch(video.contentType){
          case  'Overview':
            overview = {
              // "videoUrl": video.videoUrl,
              // "videoThumbnailUrl": video.videoThumbnailUrl,
              "videoUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/H/R/X/45731918_21_Grand_Cherokee_210325_210325101455_3.mp4',
              "videoThumbnailUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/H/R/X/45731918_21_Grand_Cherokee_210325_210325101455_3.jpg',
              "videoTitle": video.contentType,
          }
          break;
          case  'Safety':
            safety = {
              // "videoUrl": video.videoUrl,
              // "videoThumbnailUrl": video.videoThumbnailUrl,
              "videoUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/A/T/G/45731924_21_Grand_Cherokee_Segmented_Safety_210325_210325104343_3.mp4',
              "videoThumbnailUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/A/T/G/45731924_21_Grand_Cherokee_Segmented_Safety_210325_210325104343_3.jpg',
              "videoTitle": video.contentType,
          }
          break;
          case 'Performance':
            performance = {
              // "videoUrl": video.videoUrl,
              // "videoThumbnailUrl": video.videoThumbnailUrl,
              "videoUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/G/Q/G/45731920_21_Grand_Cherokee_Segmented_Performance_210326_210326031323_3.mp4',
              "videoThumbnailUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/G/Q/G/45731920_21_Grand_Cherokee_Segmented_Performance_210326_210326031323_3.jpg',

              "videoTitle": video.contentType,
          }
          break;
          case 'Technology':
            technology = {
              // "videoUrl": video.videoUrl,
              // "videoThumbnailUrl": video.videoThumbnailUrl,
              "videoUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/K/3/M/45731922_21_Grand_Cherokee_Segmented_Technology_210325_210325104312_3.mp4',
              "videoThumbnailUrl": 'https://unityworks-a.akamaihd.net/anon.unityworks/K/3/M/45731922_21_Grand_Cherokee_Segmented_Technology_210325_210325104312_3.jpg',
              "videoTitle": video.contentType,
          }
          break;
        }
      });
      return ([
        overview, performance, safety, technology
      ]);
    },

    overviewVideoUrl() {
      return this.videosSorted[0].videoUrl;
    },

  },

};
</script>