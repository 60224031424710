<template>

  <div class="btn-container">
    <a
      :href="url"
      class="btn"
      :class="[roundedCorners ? 'rounded-corners' : 'square-corners', $store.getters.activeTheme.fontBold]"
      :style= "[fullWidth ? {'width': '100%'} : {'width': 'auto'}]"
    >
      <img
        v-if="showPlay"
        src="@/assets/play.svg"
        class="play"
      >
        {{ text }}
    </a>
  </div>

</template>

<script>
export default {

  name: "secondary-btn",

  props: {
    text: {
      type: String,
      required: true,
      default: "Click Me",
    },
    url: {
      type: String,
      required: false,
      default: "#",
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    roundedCorners: {
      type: Boolean,
      required: false,
      default: false,
    },
    showChevron: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
  },

  computed: {

  },
};

</script>

<style scoped>

  .rounded-corners {
    border-radius: .14286rem;
  }

  .square-corners {
    border-radius: none;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .btn {
    background-color: rgba(99, 99, 99, 1);
    color: white;
    text-decoration: none;
    display: flex;
    padding: 15px 12px 12px 12px;
    font-size: 14px;
    border: none;
    -webkit-transition: background-color 250ms ease-in-out;
    transition: background-color 250ms ease-in-out;
    text-transform: uppercase;
  }

  .btn:hover {
    background-color: rgb(88, 88, 88) !important;
  }

  a {
    justify-content: center;
  }

  .chevron {
    width: 8px;
    right: 5px;
    padding-left: 60px;
  }

  .play {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }

  @media only screen and (max-width: 576px) {
    
    .chevron {
      display: none !important;
    }

    /* .play {
      display: none;
    } */

  }

</style>
