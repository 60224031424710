<template>

  <div id="the-feature-panels-container">

    <div
      class="feature-panel feature-panel-left"
      :style="{ 'background-image': 'url(' + model.panelPerformanceImageUrl + ')' }"
    >
      <div class="panel-inner">
        <h3 :class="$store.getters.activeTheme.fontBold">PERFORMANCE</h3>
        <p style="line-height: 18px;">{{ model.performanceDescription }}</p>
        <div class="btn-container">
          <primary-btn
            class="button"
            :text="getInventoryModelText()"
            :url="dealer.inventoryUrl"
            showChevron
          />
          <secondary-btn
            class="button"
            @click.prevent="playVideo(videos[1].videoUrl)"
            text="watch video"
            showPlay
          />
        </div>
      </div>
    </div>

    <div
      class="feature-panel feature-panel-right"
      :style="{ 'background-image': 'url(' + model.panelSafetyImageUrl + ')' }"
    >
      <div class="panel-inner">
        <h3 :class="$store.getters.activeTheme.fontBold">SAFETY</h3>
        <p style="line-height: 18px;">{{ model.safetyDescription }}</p>
        <div class="btn-container">
          <primary-btn
            class="button"
            :text="getInventoryModelText()"
            :url="dealer.inventoryUrl"
            showChevron
          />
          <secondary-btn
            @click.prevent="playVideo(videos[2].videoUrl)"
            text="watch video"
            url="https://www.google.com"
            showPlay
          />
        </div>
      </div>
    </div>

    <div
      class="feature-panel feature-panel-left"
      :style="{ 'background-image': 'url(' + model.panelTechnologyImageUrl + ')' }"
    >
      <div class="panel-inner">
        <h3 :class="$store.getters.activeTheme.fontBold">TECHNOLOGY</h3>
        <p style="line-height: 18px;">{{ model.technologyDescription }}</p>
        <div class="btn-container">
          <primary-btn
            class="button"
            :text="getInventoryModelText()"
            :url="dealer.inventoryUrl"
            showChevron
          />
          <secondary-btn
            @click.prevent="playVideo(videos[3].videoUrl)"
            text="watch video"
            url="https://www.google.com"
            showPlay
          />
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import PrimaryBtn from '@/components/PrimaryBtn.vue';
import SecondaryBtn from '@/components/SecondaryBtn.vue';

export default {
  name: "the-feature-panels",

  props: {
    model: {
      type: Object
    },
    videos: {
      type: Object
    },
    dealer: {
      type: Object
    }
  },

  components: {
    PrimaryBtn,
    SecondaryBtn,
  },

  data() {
    return {
      performanceBackground: {
        backgroundImage: this.model.panelPerformanceBackgroundUrl,
      },
      safetyBackground: {
        backgroundImage: this.model.panelSafetyBackgroundUrl,
      },
      technologyBackground: {
        backgroundImage: this.model.panelTechnologyBackgroundUrl,
      },
    };
  },

  methods: {

    playVideo(url) {
      this.$store.commit('toggleModal');
      this.$store.commit("setVideoActiveUrl", url);
      this.$store.commit('toggleVideo');
    },

    getInventoryModelText() {
      if(this.model.make == 'jeep') {
        return 'explore inventory';
      } else {
        return 'view inventory';
      }
    },

  },

  computed: {

  }
};

</script>

<style scoped>

  .feature-panel {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    margin: 3px 0px 3px 0px;
    width: 100%;
    height: 550px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .feature-panel-left {
    justify-content: flex-start;
  }

  .feature-panel-right {
    justify-content: flex-end;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
  }

  .panel-inner {
    max-width: 450px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white; padding: 25px;
  }

  .button {
    margin-right: 20px;
  }

  @media only screen and (max-width: 576px) {
    
    .panel-inner {
      max-width: 100%;
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .button {
      margin: 0px 0px 10px 0px;
    }

  }



  @media only screen and (max-width: 992px) and (min-width: 577px) {

    .feature-panel {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px;
      margin: 3px 0px 3px 0px;
      width: 100%;
      height: 550px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

  }

</style>
