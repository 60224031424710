<template #default>
  <div id="the-video-thumbnails">

    <div v-for="(video,index) in videos" :key="index" class="thumbnail-container">

      <a
        @click.prevent="playVideo(video.videoUrl)"
        href="#" class="thumbnail-image"
        :style='{ backgroundImage: "url(" + video.videoThumbnailUrl + ")", }'
        style="overflow: none; justify-content: center; align-items: center; display: flex; background-size: cover; background-position: center; width: 100%; height: 100%;"
      >
        <img class="play-hover" style="position: absolute; z-index: 50;" src="@/assets/play.svg">
      </a>

      <p
        @click.prevent="playVideo(video.videoUrl)"
        class="thumbnail-text"
      >
        {{ video.videoTitle }}
      </p>

    </div>
  </div>
</template>

<script>
export default {

  name: "the-video-thumbnails",

  props: {
    videos: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    }
  },

  methods: {
    playVideo(url) {
      this.$store.commit('toggleModal');
      this.$store.commit("setVideoActiveUrl", url);
      this.$store.commit('toggleVideo');
    }
  },

  computed: {

    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

  }
};

</script>

<style scoped>

  #the-video-thumbnails {
    background-color: white;
  }

  .thumbnail-text:hover {
    opacity: 0.7;
  }

  .thumbnail-image:hover{
    filter: brightness(1.3);
    transform: scale(1.05);
    transition: all 30ms ease-out;
    border-radius: 3px;
  }

  .thumbnail-image:focus{
    filter: brightness(1.5);
    transform: scale(1.05);
    transition: all 30ms ease-out;
    border-radius: 3px;
  }

  @media only screen and (max-width: 576px) {
    #the-video-thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-top: 5px;
      padding-top: 20px;
      padding-bottom: 50px;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

    .thumbnail-container {
      width: 90%;
      height: 235px;
      margin: 15px;
      padding-bottom: 20px;
    }

    .thumbnail-text {
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 10px;
      cursor: pointer;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 577px) {
    #the-video-thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-top: 5px;
      padding-top: 20px;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

    .thumbnail-container {
      width: 44%;
      height: 175px;
      margin: 15px;
      padding-bottom: 50px;
    }

    .thumbnail-text {
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

  }

  @media only screen and (max-width: 992px) and (min-width: 769px) {

    #the-video-thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-top: 5px;
      padding-top: 20px;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

    .thumbnail-container {
      width: 44%;
      height: 175px;
      margin: 15px;
      padding-bottom: 60px;
    }

    .thumbnail-text {
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

  }
  
  @media only screen and (max-width: 1400px) and (min-width: 993px){

    #the-video-thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: 200px;
      padding-bottom: 30px;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

    .thumbnail-container {
      width: 100%;
      height: 100%;
      max-width: 210px;
      max-height: 115px;
      min-width: 110px;
      min-height: 80px;
      margin: 15px;
    }

    .thumbnail-text {
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
      margin-bottom: 10px;
    }

  }

  @media only screen and (min-width: 1400px) {

    #the-video-thumbnails {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: 200px;
      margin-bottom: 60px;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }

    .thumbnail-container {
      width: 100%;
      height: 100%;
      max-width: 210px;
      max-height: 115px;
      min-width: 110px;
      min-height: 80px;
      margin: 0px 15px 15px 15px;
    }

    .thumbnail-text {
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
    }
  }

</style>
