<template>

  <div class="btn-container">
    <a
      @click.prevent="alert('Ready to link off when the inventory link is available!')"
      :href="url"
      class="btn"
      :class="[
        fullWidth ? 'w-100' : 'w-auto',
        roundedCorners ? 'rounded-corners' : '',
        $store.getters.activeTheme.fontBold,
      ]"
      :style="buttonStyle"
    >
      {{ text }}
      <img
        v-if="showChevron"
        :src="getChevronUrl()"
        style="height: 16px; right: 5px; margin-top: 2px; padding-left: 30px;"
        class="chevron"
      >
    </a>
  </div>

</template>

<script>
export default {

  name: "primary-btn",

  props: {
    text: {
      type: String,
      required: true,
      default: "Click Me",
    },
    url: {
      type: String,
      required: false,
      default: "#",
    },
    fullWidth: {
      type: Boolean,
      required: false,
    },
    roundedCorners: {
      type: Boolean,
      required: false,
    },
    showChevron: {
      type: Boolean,
      required: false,
    },
    showPlay: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    getChevronUrl() {
      return require('../assets/' + this.$store.getters.activeTheme.chevron);
    },

    alert(message) {
      alert(message);
    }
  },

  computed: {
    
    buttonStyle() {
      return {
        'background-color': this.$store.getters.activeTheme.accentColor,
        'color': this.$store.getters.activeTheme.btnFontColor,
      }
    },

  }
};

</script>

<style scoped>

 .rounded-corners {
    border-radius: .14286rem;
  }

  .square-corners {
    border-radius: none;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .btn {
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    padding: 15px 12px 12px 12px;
    font-size: 14px;
    border: none;
    -webkit-transition: background-color 250ms ease-in-out;
    transition: background-color 250ms ease-in-out;
  }

  .btn:hover {
    filter: brightness(90%);
  }

  a {
    justify-content: center;
  }

  @media only screen and (max-width: 576px) {
    
    .chevron {
      display: none !important;
    }

    .play {
      display: none;
    }

  }

</style>
